import React from 'react';
import styled from 'styled-components';
import { Card } from 'antd';

import { Link } from 'gatsby';

import featuredBackground from '../../../static/images/covid-19/featured-bg.png';

const Section = styled.div`
  padding: 40px 120px;
  background: url(${featuredBackground});
  background-position: 50% 50%;
  background-size: cover;

  .title {
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 36px;
    line-height: 42px;
    color: white;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
  }
  .yellow {
    font-family: 'Poppins', 'Proxima Nova', 'Open Sans', san-serif;
    color: ${props => props.theme.primeYellowPalette.original};
  }
  .description {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 42px;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
    color: white;
  }
  .services-row {
    display: flex;

    .service-card {
      margin: 8px;

      .ant-card-body {
        padding: 20px;
      }
    }

    .card-title {
      font-size: 24px;
      line-height: 24px;
      font-weight: 600;
      color: ${props => props.theme.primeBluePalette.original};
      margin-bottom: 8px;
    }
    .card-description {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 8px;
    }
    .card-link {
      font-weight: 600;
      text-decoration: underline;
    }
  }
  @media only screen and (max-width: 1196px) {
    padding: 40px 60px;
  }
  @media only screen and (max-width: 1016px) {
    padding: 40px 40px;
    .title {
      margin-bottom: 8px;
      font-size: 32px;
      line-height: 38px;
    }
    .description {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 42px;
    }
  }
  @media only screen and (max-width: 960px) {
    padding: 40px 40px;
    .title {
      margin-bottom: 8px;
      font-size: 28px;
      line-height: 32px;
    }
    .description {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 36px;
    }
  }
  @media only screen and (max-width: 767px) {
    .services-row {
      flex-direction: column;
      padding: 0px 40px;
    }
    margin-bottom: 60px;
  }
  @media only screen and (max-width: 555px) {
    padding: 20px;
    .services-row {
      padding: 0px 20px;
    }
  }
  @media only screen and (max-width: 450px) {
    .services-row {
      padding: 0px;
    }
  }
`;

function FeaturedServicesSection() {
  return (
    <Section>
      <h1 className="title">
        <span className="yellow">Pandemic-ready services</span> to help you
        adapt
      </h1>
      <p className="description">
        Our extensive coverage of services are tailor-fit to needs that are
        emergent during this situation.
      </p>
      <div className="services-row">
        <Card className="service-card">
          <div className="card-title">Business Resumption</div>
          <div className="card-description">
            Addressing pressing operational concerns post-lockdown with
            professional advice & recommendations based on market trends, with
            industry best practices in line with government-mandated guidelines{' '}
          </div>
          <Link className="card-link" to="/">
            CLICK HERE &gt;
          </Link>
        </Card>
        <Card className="service-card">
          <div className="card-title">Real Estate Research</div>
          <div className="card-description">
            In-depth real estate market insights and advisory services providing
            property owners with valuable recommendations on a property’s best
            use, market readiness, design, and financial costs & returns,
            thereby assisting owners in making informed investment decisions.{' '}
          </div>
          <Link className="card-link" to="/">
            CLICK HERE &gt;
          </Link>
        </Card>
        <Card className="service-card">
          <div className="card-title">E2E Project Consultancy</div>
          <div className="card-description">
            Experts from various fields come together to assist and advice
            investors, property owners, and developers on how to gain maximum
            returns from their vacant property or idle land.{' '}
          </div>
          <Link className="card-link" to="/">
            CLICK HERE &gt;
          </Link>
        </Card>
      </div>
    </Section>
  );
}

export default FeaturedServicesSection;
