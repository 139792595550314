import React from 'react';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import REBICIssue from '../../../static/images/covid-19/REBIC.png';

const Feature = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 650px;
  padding: 80px;

  .content-section {
    width: 70%;
    padding: 40px;
    flex: 7 1 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .image-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 3 1 auto;
    padding: 40px 20px;
    .publication {
      /* max-width: 200px; */
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin: 0;
    }
  }

  .subtitle {
    letter-spacing: 2px;
    font-size: 20px;
    margin-bottom: 6px;
    font-weight: 600;
    color: ${props => props.theme.blackPalette.light};
  }
  .title {
    font-weight: 600;
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 42px;
  }
  .blue {
    font-family: 'Poppins', 'Proxima Nova', 'Open Sans', san-serif;
    color: ${props => props.theme.primeBluePalette.original};
  }
  .description {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 42px;
  }
  .get-report-link {
    background-color: ${props => props.theme.primeBluePalette.original};
    color: ${props => props.theme.primeYellowPalette.original};
    padding: 12px 20px;

    font-size: 24px;
    font-weight: 600;
    text-decoration: underline;
    line-height: 24px;
  }

  @media only screen and (max-width: 1096px) {
    padding: 40px;
  }
  @media only screen and (max-width: 1016px) {
    padding: 40px;
    .content-section {
      padding: 20px;
    }
    .subtitle {
      font-size: 16px;
      margin-bottom: 6px;
    }
    .title {
      margin-bottom: 20px;
      font-size: 32px;
      line-height: 38px;
    }
    .description {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 36px;
    }
  }
  @media only screen and (max-width: 960px) {
    padding: 20px;

    .content-section {
      padding: 20px;
    }

    .subtitle {
      font-size: 14px;
      margin-bottom: 6px;
    }
    .title {
      margin-bottom: 20px;
      font-size: 28px;
      line-height: 32px;
    }
    .description {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 36px;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 60px;

    .content-section {
      width: auto;
      padding: 40px;
      flex: 1 1 auto;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .image-section {
      flex: 1 1 auto;
      padding: 0px 40px;
      .publication {
        max-width: 200px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin: 0;
      }
    }
    .subtitle {
      font-size: 16px;
      margin-bottom: 6px;
    }
    .title {
      margin-bottom: 20px;
      font-size: 32px;
      line-height: 38px;
    }
    .description {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 36px;
    }
  }
  @media only screen and (max-width: 650px) {
    padding: 100px 20px;
    .image-section {
      padding: 0px 20px;
    }
    .content-section {
      padding: 20px;
    }
    .subtitle {
      font-size: 14px;
      margin-bottom: 6px;
    }
    .title {
      margin-bottom: 20px;
      font-size: 28px;
      line-height: 32px;
    }
    .description {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 36px;
    }
    .get-report-link {
      padding: 10px 18px;

      font-size: 20px;
      line-height: 20px;
    }
    
  }
  @media only screen and (max-width: 374px) {
    padding: 80px 10px;
    .image-section {
      padding: 0px 10px;
    }
    .content-section {
      padding: 10px;
    }
  }
`;

function PublicationFeature() {
  return (
    <Feature>
      <div className="image-section">
        <img className="publication" src={REBICIssue} alt="Real Estate Business Intelligence Compendium issue in background"/>
      </div>
      <div className="content-section">
        <span className="subtitle">FEATURED PUBLICATION</span>
        <h1 className="title">
          Chart your course through the new normal with our free{' '}
          <span className="blue">
            Real Estate Business Intelligence Compendium
          </span>
        </h1>
        <p className="description">
          Learn the sentiments and insights of nearly 500{' '}
          <b>landlords, occupiers and investors</b> on the impact of COVID-19 in
          the overall economic and real estate business in the Philippines.
        </p>
        <span>
          <OutboundLink
            href="https://realestateintelligence.primephilippines.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="get-report-link"
          >
            DOWNLOAD THE REPORT
          </OutboundLink>
        </span>
      </div>
    </Feature>
  );
}

export default PublicationFeature;
