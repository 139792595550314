import React from 'react';
import styled from 'styled-components';

import responseBGImage from '../../../static/images/covid-19/response-bg.png';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 55vh;
  max-height: 550px;
  min-height: 450px;
  background: url(${responseBGImage});
  background-position: 50% 50%;
  background-size: cover;
  padding: 80px;

  .subtitle {
    color: white;
    letter-spacing: 2px;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
    margin-bottom: 12px;
  }
  .title {
    color: white;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
    font-weight: 600;
    margin-bottom: 36px;
    font-size: 64px;
  }
  .our-statement {
    color: white;
    font-size: 24px;
    line-height: 28px;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
    width: 50%;
  }
  @media only screen and (max-width: 1110px) {
    .our-statement {
      width: 85%;
    }
  }
  @media only screen and (max-width: 800px) {
    .title {
      margin-bottom: 24px;
      font-size: 56px;
    }
    .our-statement {
      font-size: 20px;
      line-height: 24px;
      width: 100%;
    }
  }
  @media only screen and (max-width: 636px) {
    padding: 40px;
    height: 40vh;
    max-height: 450px;
    min-height: 350px;
    .subtitle {
      margin-bottom: 8px;
      font-size: 14px;
    }
    .title {
      margin-bottom: 20px;
      font-size: 52px;
    }
    .our-statement {
      font-size: 18px;
      line-height: 22px;
      width: 100%;
    }
  }
  @media only screen and (max-width: 522px) {
    padding: 30px;
    height: 60vh;
    max-height: 550px;
    min-height: 450px;
  }
  @media only screen and (max-width: 380px) {
    padding: 15px;
    .title {
      margin-bottom: 20px;
      font-size: 48px;
    }
  }
`;

function PageHeader() {
  return (
    <Header>
      <div>
        <span className="subtitle">OUR COVID-19 RESPONSE</span>
        <h1 className="title">Progression towards renewal</h1>
        <p className="our-statement">
          In solidarity with you during this ongoing COVID-19 situation, we are
          committed to assisting you and your business as we navigate this path
          towards recovery, through our insights and services.
        </p>
      </div>
    </Header>
  );
}

export default PageHeader;
