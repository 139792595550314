import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import featuredBackground from '../../../static/images/covid-19/consultation-bg.jpg'; // to replace once I get updated pic from paul

const Feature = styled.div`
  display: flex;
  flex-direction: row;
  height: 450px;
  padding: 40px;
  margin-bottom: 40px;

  .content-section {
    width: 40%;
    padding: 40px;
    flex: 5 1 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .image-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 5 1 auto;
    padding: 40px 20px;
    .consult-with-us {
      max-width: 500px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin: 0;
    }
  }

  .title {
    font-weight: 600;
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 42px;
  }
  .blue {
    font-family: 'Poppins', 'Proxima Nova', 'Open Sans', san-serif;
    color: ${props => props.theme.primeBluePalette.original};
  }
  .description {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 42px;
  }
  .book-a-consultation {
    background-color: ${props => props.theme.primeBluePalette.original};
    color: ${props => props.theme.primeYellowPalette.original};
    padding: 12px 20px;

    font-size: 24px;
    font-weight: 600;
    text-decoration: underline;
    line-height: 24px;
  }

  @media only screen and (max-width: 1096px) {
    padding: 40px;
    .image-section {
      .consult-with-us {
        max-width: 400px;
      }
    }
  }
  @media only screen and (max-width: 1016px) {
    padding: 40px;
    .content-section {
      padding: 20px;
    }
    .subtitle {
      font-size: 16px;
      margin-bottom: 6px;
    }
    .title {
      margin-bottom: 20px;
      font-size: 32px;
      line-height: 38px;
    }
    .description {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 36px;
    }
  }
  @media only screen and (max-width: 960px) {
    padding: 20px;

    .content-section {
      padding: 20px;
    }
    .image-section {
      .consult-with-us {
        max-width: 300px;
      }
    }

    .subtitle {
      font-size: 14px;
      margin-bottom: 6px;
    }
    .title {
      margin-bottom: 20px;
      font-size: 28px;
      line-height: 32px;
    }
    .description {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 36px;
    }
    .book-a-consultation {
      padding: 10px 18px;

      font-size: 20px;
      line-height: 20px;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 60px;

    .content-section {
      width: auto;
      padding: 40px;
      flex: 1 1 auto;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .image-section {
      flex: 1 1 auto;
      padding: 0px 40px;
      .consult-with-us {
        max-width: 500px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin: 0;
      }
    }
    .subtitle {
      font-size: 16px;
      margin-bottom: 6px;
    }
    .title {
      margin-bottom: 20px;
      font-size: 32px;
      line-height: 38px;
    }
    .description {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 36px;
    }
    
  }
  @media only screen and (max-width: 650px) {
    padding: 100px 20px;
    .image-section {
      padding: 0px 20px;
    }
    .content-section {
      padding: 20px;
    }
    .subtitle {
      font-size: 14px;
      margin-bottom: 6px;
    }
    .title {
      margin-bottom: 20px;
      font-size: 28px;
      line-height: 32px;
    }
    .description {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 36px;
    }
    .book-a-consultation {
      padding: 10px 18px;

      font-size: 20px;
      line-height: 20px;
    }
  }
  @media only screen and (max-width: 374px) {
    padding: 80px 10px;
    .image-section {
      padding: 0px 10px;
    }
    .content-section {
      padding: 10px;
    }
  }
`;

function ConsultationCTASection() {
  return (
    <Feature>
      <div className="image-section">
        <img className="consult-with-us" alt="consulting professionals" src={featuredBackground} />
      </div>
      <div className="content-section">
        <h1 className="title">
          We're here to help you during this period of uncertainty.{' '}
          <span className="blue">Talk to us today.</span>
        </h1>
        <p className="description">
          Our experts are more than happy to help you learn about how we can
          assist with your business resumption objectives, asset management,
          research and other real estate needs.
        </p>
        <span>
          <Link to="/contact-us" className="book-a-consultation">
            BOOK A CONSULTATION
          </Link>
        </span>
      </div>
    </Feature>
  );
}

export default ConsultationCTASection;
