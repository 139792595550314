import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Row, Col } from 'antd';

import Layout from '../components/layout';
import SEO from '../components/seo';

import PageHeaderSection from '../components/covid-19-page/PageHeaderSection';
import PublicationFeature from '../components/covid-19-page/PublicationFeature';
import FeaturedServicesSection from '../components/covid-19-page/FeaturedServicesSection';
import ConsultationCTASection from '../components/covid-19-page/ConsultationCTASection';

import IndustryInsightsComponent from '../components/home-page/IndustryInsightsComponent';
import ResearchPublicationComponent from '../components/home-page/ResearchPublicationComponent';
import UpComingEventsComponent from '../components/home-page/UpComingEventsComponent';
import FeaturedHighlightComponent from '../components/home-page/FeaturedHighlightComponent';

const PageWrapper = styled.div`
  padding-top: 89px;
  overflow: hidden;
  max-width: 1366px;
  margin: auto;
`;

function COVID19ResponsePage({location}) {
  const data = useStaticQuery(graphql`
    {
      allStrapiRealEstateInsight(
        limit: 5
        sort: { order: DESC, fields: published_date }
        filter: { tags: { glob: "*COVID-19*" } }
      ) {
        nodes {
          author
          description
          strapiId
          url_link
          title
          tags
          published_date(formatString: "MMMM DD, YYYY")
        }
      }
      allStrapiPublications(
        limit: 4
        sort: { order: DESC, fields: publish_date }
        filter: { tags: { glob: "*COVID-19*" } }
      ) {
        nodes {
          url_path
          title
          strapiId
          publish_date(formatString: "MMMM DD, YYYY")
          description
          image_path
        }
      }
    }
  `);
  return (
    <Layout source="Covid-19 Response Page">
      <SEO
        title="Our COVID-19 Response"
        description="In solidarity with you during this ongoing COVID-19 situation, we are committed to assisting you and your business as we navigate this path towards recovery, through our insights and services."
        meta={[
          {
            property: 'og:image',
            content: `${process.env.IMAGE_ASSETS}/real-estate-insights/130/thumb/2020040810415781969.jpg`,
          },
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content: "Commercial Real Estate Service Provider in the Philippines, Commercial Real Estate Service Firm in the Philippines, Commercial Real Estate Leasing Firm  in the Philippines, Commercial Real Estate Advisory Firm in the Philippines, Commercial Real Estate Consultancy Firm in the Philippines, The  Leading Commercial Real Estate Consultancy Firm in the Philippines, The Leading Commercial Real Estate Leasing Agency in the Philippines, The Leading Commercial Real Estate Advisory  Firm in the philippines, The Leading Commercial Real Estate Service Firm  in the Philippines, The Leading Commercial Real Estate Service Provider  in the Philippines, Industrial Leasing Solutions in the Philippines, Warehouse Leasing Solutions in the Philippines, Real Estate Leasing Solutions in the Philippines, Retail and Office Leasing Solutions in the Philippines, Retail Leasing Solutions in the Philippines, Real Estate Brokerage Leasing Solutions in the Philippines, Real Estate Research and Advisory Solutions in the Philippines, Property Research and Advisory Solutions in the Philippines, Property and Facilities Management Solutions in the Philippines, Property and Facilities Management Solutions in the Philippines, Property and Facilities Management Solutions in the Philippines, Industrial Warehouse leasing, Office Leasing Solution, Retail Leasing Solution, Real Estate Research and Advisory Solutions, Real Estate Brokerage Leasing, Real Estate Leasing Solutions, Property Leasing Services",
          },
        ]}
      />
      <PageWrapper>
        <PageHeaderSection />
        <PublicationFeature />
        <FeaturedServicesSection />

        <Row
          type="flex"
          justify="center"
          style={{
            backgroundColor: '#f2f2f2',
            marginBottom: 50,
            padding: '50px 0px',
          }}
        >
          <Col xs={23} sm={23} md={23} lg={23} style={{ marginBottom: 25 }}>
            <ResearchPublicationComponent
              publications={data.allStrapiPublications.nodes}
              title="COVID-19 PUBLICATIONS"
              seeMore="See other publications here"
            />
          </Col>

          <Col xs={23} sm={23} md={23} lg={23}>
            <Row gutter={[20, 20]} type="flex" justify="center">
              <Col xs={23} sm={23} md={14} lg={16} xl={16} xxl={16}>
                <IndustryInsightsComponent
                  blogs={data.allStrapiRealEstateInsight.nodes}
                  title="COVID-19 INDUSTRY INSIGHTS"
                />
              </Col>
              <Col xs={23} sm={23} md={10} lg={8}>
                <UpComingEventsComponent />
                <FeaturedHighlightComponent />
              </Col>
            </Row>
          </Col>
        </Row>

        <ConsultationCTASection />
      </PageWrapper>
    </Layout>
  );
}

export default COVID19ResponsePage;
